<template>
<div v-if="getAllowFullSite" class="main content-page urgency-of-history">

    <div class="vertical-image"></div>

    <div class="content-page-inner">
        <h1>The Urgency of History</h1>
        <p>In the 5,000 years of recorded history, 26 superpowers have risen to preeminence — and all 26 have inevitably fallen. Following a clear pattern from Outburst and Conquest to Decadence and Collapse, every superpower repeated the same pattern time and again.</p>
        <p>America is the 27th country which has risen to preeminence and is now in the last stage in the life of a society — Late Decadence. America is ripe for collapse!</p>
        <p>Throughout history, every superpower which fell has caused a power vacuum which created prolonged seasons of war and conflict as nations then vied for position. It was the collapse of the Roman Empire which ushered in the Dark Ages and initiated centuries of wars and conflicts across the known world. It was the collapse of Britain’s superpower status which ushered in two World Wars and witnessed the bloodiest century in all of history. Superpowers are far from perfect, but they have brought stability to the world and have proven by far to be the most advantageous system for people and their societies to flourish.</p>
        <p>Superpower eras have also played a significant role in the advance of the Kingdom. Because superpowers bring relative peace, stability, development, infrastructure, and prosperity, the Church has always concurrently experienced seasons of significant, accelerated growth and expansion. but when superpowers collapse, history demonstrates that the Church falls into long periods of decline. This clear pattern has played out over the last 2,000 years with significant global Church growth occurring during the Roman, Mongolian, British, and now American superpower eras. But in the chaotic periods between the collapse of one superpower and the rise of the next, the Church has always seen steep global decline. Preserving America is critical for the world right now, not only for the sake of global human flourishing and the value of human life, but most importantly for the sake of advancing Christ’s Kingdom in every country on earth.</p>
        <p>If we were able to preserve America, what God could accomplish in this season through our unity and collaboration could be the most momentous advance in all of Church history! For the first time ever, completion of the Great Commission is now not just possible but is within our reach! Current projections say that by the mid-2030’s translation efforts could complete a Bible in every language, and missionaries could reach the 3.2 billion Unreached People with the Gospel. This generation could actually finish the task Christ began 2,000 years ago. To do so, America must remain strong for another 15 years, and we must send 200,000 new workers to the Unreached Peoples in the 10/40 window.</p>
        <p>But America doesn’t have 15 years. In fact, most conservative historians believe America’s collapse to be imminent. The current factors which contribute to Late Decadence and Collapse have been thoroughly documented. America hasn’t learned from history, but rather, has played it by the book! Now, in just a few short years, China is on track to overtake America not only as the dominant economic power in the world, but as the dominant military power as well. When that tipping point is reached, America’s preeminence will be challenged, and the world will descend into war and chaos once again while the Church witnesses yet another long season of decline. Undermined from within and soon overthrown from without, America’s only hope is a renewed passion for Christ, unity, repentance, awakening, revival, cultural reformation, evangelism, and missions.</p>
        <p>To that end, The Great Awakening Project is gathering churches, ministries, organizations, leaders, and individual believers centering on a the biblical promise found in Jeremiah 18:7-8. Our key leadership are individuals with decades of experience in stewarding previous moves of God and are men and women who have expertise in all Seven Spheres of Influence in society. We dare to firmly believe that God could once again move in and through His Church in this critical season via coordinated communications and large-scale collaborative campaigns and events focused on passion for Christ, unity, repentance, awakening, revival, cultural reformation, evangelism, and missions. But movements are not structures, organizations, or even leaders. Movements are people. You are “the Church!” You are God’s redemptive plan for America and for the world! Together we can push back the tide of the coming Collapse and complete the Great Commission in our lifetimes.</p>
        <p>If we do not stand up with boldness, devote ourselves to “sincere and pure devotion to Christ” (II Cor. 11:3), and collaborate in unity, it could be generations before the Church has this opportunity again.</p>
        <p>Rise up! Join the movement. Change history. Alter eternity!</p>
        <router-link to="/contact"><button class="button-dark last-button">Ready to Connect?</button></router-link>
    </div>
</div>
</template>

<script>
export default {
    name: 'urgency-of-history',
    data() {
        return {
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        }
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss" scoped>
.vertical-image {
    overflow: hidden;
    background-image: url("~@/assets/urgencyofhistory1.jpg");
    background-size: cover;
    background-position: center bottom;
}
.content-page-inner {
    padding: 4em;
    text-align: left;
}
</style>
